<template lang="pug">
    .dialogs(:class="{hidden: chatAsideHidden && pageWidth > 990}")
        loading(v-if="loading")._loading
        ._search(v-if="!isChatWithMerlinAI")
            ._search-inner
                ._search-icon-box
                    svg-icon(icon-name="search")._search-icon
                input(type="text" v-model="term" :placeholder="locale('search')")._search-input
        ._toggle-menu(@click="changeChatHidden()")
            svg-icon(icon-name="toggle-menu" v-if="!chatAsideHidden")._toggle-menu-icon
            svg-icon(icon-name="untoggle-menu" v-else)._toggle-menu-icon.-big
            span._toggle-menu-text Свернуть
        ._back(v-if="isChatWithMerlinAI" @click="goToMessages")
            svg-icon(icon-name="chevron-left")._back-icon
            ._back-text Чаты с пользователями
        perfect-scrollbar(v-if="friends.length" ref="scrollWrapper" infinite-wrapper)._list
            ._chat(v-for="friend in friends" :key="friend.id" @click="openFriendChat(friend.userId)")
                ._chat-avatar
                    user-photo(:photo="friend.personAvatar")
                ._chat-info
                    ._chat-name {{ formatUserName(friend.personName) }}
                    ._chat-message {{ locale("open") }}
        perfect-scrollbar(v-else-if="conversations.length" ref="scrollWrapper" infinite-wrapper)._list
            ._list-wrap(:class="{active: isChatWithMerlinAI && isMobileView}" v-if="!isChatWithMerlinAI")
                ._back(v-if="isChatWithMerlinAI && isMobileView" @click="goToMessages")
                    svg-icon(icon-name="chevron-left")._back-icon
                router-link._chat(v-if="!conversation.isBot" v-for="conversation in filterConversations" :key="conversation.id" :to="`/messages/${conversation.receiverId}${conversation.receiverId === merlinConversationId && isDesktopView ? '/themes/'+firstChatTheme.id : ''}`" active-class="active" :class="{blocked: !formatLastMessage(conversation)}")
                    ._chat-avatar
                        user-photo(:photo="conversation.receiverPhoto")
                    ._chat-info
                        ._chat-name {{ formatUserName(conversation.receiverName) }}
                        ._chat-message(v-html="formatLastMessage(conversation)" v-if="conversation.message && conversation.receiverId !== merlinConversationId")
                        ._chat-message.-image(v-else-if="conversation.image && conversation.receiverId !== merlinConversationId") {{ locale("image") }}
                        ._chat-message.-image(v-if="conversation.receiverId === merlinConversationId") {{ locale("attempts-left", [myUser.chatMessageAttempts]) }}
                    ._chat-data
                        ._chat-unread-count(v-if="conversation.unread") {{ conversation.unread }}
                        ._chat-date(v-else-if="conversation.updatedAt") {{ smartLocalizedDate(conversation.updatedAt) }}

            ._themes(v-if="showGPTChats")
                ._themes-title GPT-чаты
                ._new-theme
                    ui-button(type="bordered" @click.native="newChat" size="small")._add-btn
                        template(v-slot:content)
                            svg-icon(icon-name="plus")._add-icon
                            | {{ locale("new-chat") }}
                ._theme.gpt(v-for="(theme, index) in GPTThemes" v-if="index < maxGPTChats" :key="theme.id" :class="{pinned: theme.isPinned ,active: themeId === theme.id}" @click="goToTheme(theme)")
                    svg-icon(icon-name="chat-message")._gpt-icon
                    svg-icon(icon-name="pinned" v-if="theme.isPinned")._theme-fixed
                    ._theme-name(v-if="!theme.renameActive")
                        span._theme-title {{ theme.title }}
                        //span(v-if="theme.isPinned")._credit-count 1 запрос — 1 кредит
                        //span(v-else)._credit-count 1 кредит за каждые {{ IS_NEURAL_FREE ? 5000 : 2000 }} токенов
                    input._theme-input(v-else v-model="theme.newTitle")
                    ._theme-ctrl(v-if="!theme.isPinned && !theme.renameActive")
                        svg-icon(icon-name="edit" added-catalog="themes" @click.native.stop="theme.renameActive = true")._theme-icon.-edit
                        svg-icon(icon-name="delete" added-catalog="themes" @click.native.stop="confirmDeleteTheme(theme)")._theme-icon.-delete
                    ._theme-ctrl(v-else-if="theme.renameActive")
                        svg-icon(icon-name="check" @click.native.stop="changeThemeTitle(theme)")._theme-icon.-check
                ._more(v-if="GPTThemes.length > maxGPTChats" @click="maxGPTChats += 10")
                    span Показать еще
            ._themes(v-if="isChatWithMerlinAI && fixedThemes.length")
                ._themes-title Популярные нейросети
                //._new-theme
                //    ui-button(type="bordered" @click.native="newChat" size="small")._add-btn
                //        template(v-slot:content)
                //            svg-icon(icon-name="plus")._add-icon
                //            | {{ locale("new-chat") }}
                    //(theme.title !== 'Runway' || isAdmin) &&
                ._theme(v-if="theme.title !== 'Stable Diffusion Video'" v-for="theme in fixedThemes" :key="theme.id" :class="{pinned: theme.isPinned ,active: themeId === theme.id, }" @click="theme.title === 'ChatGPT' ? toggleGPTChatsVisibility() : goToTheme(theme)")
                    img(:src="require(`../../../assets/images/ai-logos/${theme.title}.png`)" v-if="theme.isPinned")._theme-logo
                    svg-icon(icon-name="pinned" v-if="theme.isPinned")._theme-fixed
                    ._theme-name(v-if="!theme.renameActive")
                        span._theme-title {{ theme.title }}
                        //span(v-if="theme.title === 'Pika'")._credit-count Временно не доступно
                        //span(v-else)._credit-count 1 кредит за каждые {{ IS_NEURAL_FREE ? 5000 : 2000 }} токенов
                    input._theme-input(v-else v-model="theme.newTitle")
                    ._theme-ctrl(v-if="!theme.isPinned && !theme.renameActive")
                        svg-icon(icon-name="edit" added-catalog="themes" @click.native.stop="theme.renameActive = true")._theme-icon.-edit
                        svg-icon(icon-name="delete" added-catalog="themes" @click.native.stop="confirmDeleteTheme(theme)")._theme-icon.-delete
                    ._theme-ctrl(v-else-if="theme.renameActive")
                        svg-icon(icon-name="check" @click.native.stop="changeThemeTitle(theme)")._theme-icon.-check

            ._themes(v-if="isChatWithMerlinAI && fixedThemes.length")
                ._themes-title Пользовательские боты
                ._theme(v-for="theme in assistantsConversations" :key="theme.id" :class="{active: theme.receiverId === assistantId}" @click="goToAssistant(theme)")
                    ._theme-logo-box
                        user-photo(:photo="theme.receiverPhoto")
                    ._theme-name(v-if="!theme.renameActive")
                        span._theme-title {{ theme.receiverName }}
                    ._theme-ctrl
                        svg-icon(icon-name="delete" added-catalog="themes" @click.native.stop="confirmDeleteConversation(theme)")._theme-icon.-delete

                ._new-theme
                    ui-button(type="bordered" size="small" @click.native="$router.push('/assistants/my')")._add-btn
                        template(v-slot:content)
                            svg-icon(icon-name="plus")._add-icon
                            | {{ assistantsBtn }}
                    ui-button(type="bordered" size="small" @click.native="$router.push('/assistants/')")._add-btn
                        template(v-slot:content)
                            svg-icon(icon-name="catalog")._catalog-icon
                            | Каталог ботов
        ._no-chats(v-else)
            svg-icon(icon-name="message")._no-chats-icon
            ._no-chats-text {{ locale("nothing") }}
</template>

<script>
import gql from "graphql-tag";
import getConversationsQuery from "@/graphql/queries/getConversations.query.graphql";
import userTypingSubscription from "@/graphql/subscriptions/userTyping.subscription.graphql";
import UserPhoto from "../../ui/user-photo/user-photo";
import { sharedMixins } from "../../../mixins/sharedMixins";
import friendListQuery from "@/graphql/queries/friendList.query.graphql";
import getThemesQuery from "@/graphql/queries/getThemes.query.graphql";
import deleteThemeMutation from "@/graphql/mutations/deleteTheme.mutation.graphql";
import deleteConversationMutation from "@/graphql/mutations/deleteConversation.mutation.graphql";
import newThemeMutation from "@/graphql/mutations/newTheme.mutation.graphql";
import renameThemeMutation from "@/graphql/mutations/renameTheme.mutation.graphql";
import UiButton from "@/components/ui/ui-button/ui-button.vue";
import renameMerlinChatSubscription from "@/graphql/subscriptions/renameMerlinChat.subscription.graphql";
import getMJKeysQuery from "@/graphql/queries/getMJKeys.query.graphql";

export default {
    name: "Dialogs",
    components: { UiButton, UserPhoto },
    props: {
        newConversation: {
            type: Object,
            default: () => {},
            required: false,
        },
        themesUpdater: {
            type: Number,
            default: 0,
            required: false,
        },
        readConversationTime: {
            type: Number,
            default: 0,
            required: false,
        },
        readConversationId: {
            type: Number,
            default: 0,
            required: false,
        },
    },
    data() {
        return {
            loading: true,
            conversations: [],
            friends: [],
            page: 1,
            limit: 50,
            term: "",
            termTimeout: null,
            userTypingTimeouts: [],
            isTypingSubscribed: false,
            merlinConversationId: "18d68acc-b1cd-11ed-b5e6-02420a000cf6",
            themes: [],
            deleteThemeId: 0,
            deleteConversationId: 0,
            maxGPTChats: 5,
            showGPTChats: false
        };
    },
    computed: {
        assistantsCount: (state) =>
            state.$store.getters["user/assistantsCount"],
        assistantsBtn: (state) =>
            state.assistantsCount > 0 ? "Мои ассистенты" : "Создать ассистента",
        fixedThemes: (state) => {
            const chatGptTheme = {
                id: "static-chatgpt-id",
                title: "ChatGPT",
                isPinned: true,
                receiverId: "chatgpt-id"
            };

            if(!state.showGPTChats){
                return [chatGptTheme, ...state.themes.filter((theme) => theme.isPinned)];
            }
            return [...state.themes.filter((theme) => theme.isPinned)];
        },
        GPTThemes: (state) => state.themes.filter((theme) => !theme.isPinned),
        firstChatTheme: (state) =>
            state.themes.find((theme) => !theme.isPinned) || { id: 0 },
        myUserUuid: (state) => state.$store.getters["user/userUuid"],
        chatAsideHidden: (state) => state.$store.getters["chatAsideHidden"],
        chatId: (state) => state.$route.params.chatId,
        themeId: (state) => +state.$route.params.theme_id,
        assistantId: (state) => state.$route.params.assistantId,
        filterConversations: function () {
            if (this.isChatWithMerlinAI) {
                return this.conversations.filter(
                    (conversation) =>
                        conversation.receiverId === this.merlinConversationId,
                );
            }
            return this.conversations;
        },
        assistantsConversations: (state) =>
            state.conversations.filter((conversation) => conversation.isBot),
        isChatWithMerlinAI: (state) =>
            state.merlinConversationId === state.chatId,
    },

    watch: {
        themesUpdater() {
            this.getThemes();
        },
        term() {
            clearTimeout(this.termTimeout);
            if (this.term === "") {
                this.friends = [];
                return;
            }
            this.termTimeout = setTimeout(() => {
                this.friends = [];
                this.getFriends();
            }, 500);
        },
        newConversation(conversation) {
            let receiverId = conversation.conversation.senderId;
            let conversationId = conversation.conversation.id;

            if (conversation.conversation.isBot) {
                receiverId = conversation.withPersonId;
                conversationId = conversation.id;
            }

            const chat = this.conversations.find(
                (c) => Number(c.id) === Number(conversationId),
            );
            if (chat === undefined) {
                this.conversations.unshift({
                    id: conversation.conversation.id,
                    receiverPhoto: conversation.withAvatar,
                    receiverName: conversation.withName,
                    receiverId,
                    isTyping: false,
                    isBot: true,
                    message: "",
                });
            }
        },
        readConversationTime() {
            const chat = this.conversations.find(
                (c) => Number(c.id) === Number(this.readConversationId),
            );
            if (chat) {
                chat.unread = 0;
            }
        },
    },
    created() {
        // Check if the user has already seen ChatGPT
        const hasSeenChatGPT = localStorage.getItem("hasSeenChatGPT");
        if (hasSeenChatGPT) {
            this.showGPTChats = true; // If seen, always show ChatGPT
        } else {
            this.showGPTChats = false; // First-time user
            localStorage.setItem("hasSeenChatGPT", "true"); // Mark as seen
        }
    },
    async mounted() {
        setTimeout(() => {
            this.showTips("chat-1");
        }, 1000);
        this.merlinConversationId = process.env.MERLIN_CONVERSATION_ID
            ? process.env.MERLIN_CONVERSATION_ID
            : this.merlinConversationId;
        await this.getConversations();
        await this.getThemes();
        await this.subscribeToRenameTheme();
        if (this.themeId) {
            await this.$store.dispatch("set", {
                name: "activeThemeName",
                value: this.themes.find((theme) => +this.themeId === +theme.id)
                    ?.title,
            });
        }
    },
    methods: {
        toggleGPTChatsVisibility() {
            this.showGPTChats = !this.showGPTChats;
        },
        async subscribeToRenameTheme() {
            const observer = this.$apollo.subscribe({
                query: gql(renameMerlinChatSubscription),
                variables: {
                    userId: this.myUser.uuid,
                },
                client: "chatClient",
            });

            const state = this;

            observer.subscribe({
                next(r) {
                    if (
                        r.data.renameMerlinChat.receiverId === state.myUser.uuid
                    ) {
                        const theme = state.themes.find(
                            (theme) =>
                                theme.id === r.data.renameMerlinChat.themeId,
                        );
                        if (theme?.id) {
                            theme.title = r.data.renameMerlinChat.newTitle;
                        }
                    }
                },
                error() {
                    console.log("rename error");
                },
            });
        },
        goToMessages() {
            console.log("go to");
            this.$router.push("/messages");
        },
        changeThemeTitle(theme) {
            this.$apollo
                .mutate({
                    mutation: gql(renameThemeMutation),
                    variables: {
                        id: theme.id,
                        newTitle: theme.newTitle,
                    },
                    client: "chatClient",
                })
                .then(() => {
                    theme.renameActive = false;
                    theme.title = theme.newTitle;
                });
        },
        newChat() {
            this.$apollo
                .mutate({
                    mutation: gql(newThemeMutation),
                    client: "chatClient",
                })
                .then((r) => {
                    this.getThemes();
                    this.$store.dispatch("set", {
                        name: "activeThemeName",
                        value: "Новый чат",
                    });
                    this.$router.push(
                        `/messages/${this.merlinConversationId}/themes/${r.data.newTheme.id}`,
                    );
                });
        },
        confirmDeleteTheme(theme) {
            this.deleteThemeId = theme.id;
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: this.locale("delete.title"),
                text: this.locale("delete.desc"),
                buttons: true,
                confirmText: this.locale("delete.btn"),
                callback: this.deleteTheme,
            });
        },
        confirmDeleteConversation(theme) {
            this.deleteConversationId = theme.id;
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: "Удалить бота?",
                text: "Вы действительно хотите удалить бота из списка ботов?",
                buttons: true,
                confirmText: "Удалить",
                callback: this.deleteConversation,
            });
        },
        deleteConversation() {
            this.$apollo
                .mutate({
                    mutation: gql(deleteConversationMutation),
                    client: "chatClient",
                    variables: {
                        conversationId: +this.deleteConversationId,
                    },
                })
                .then(() => {
                    this.deleteConversationFromList();
                });
        },
        deleteConversationFromList() {
            const item = this.conversations.find(
                (item) => +item.id === +this.deleteConversationId,
            );
            this.conversations.splice(this.conversations.indexOf(item), 1);
            this.deleteConversationId = 0;
        },
        deleteTheme() {
            this.$apollo
                .mutate({
                    mutation: gql(deleteThemeMutation),
                    client: "chatClient",
                    variables: {
                        id: this.deleteThemeId,
                    },
                })
                .then(() => {
                    if (this.deleteThemeId === +this.themeId) {
                        this.$router.push(
                            `/messages/${this.merlinConversationId}`,
                        );
                    }
                    this.deleteThemeId = 0;
                    this.getThemes();
                });
        },
        async goToTheme(theme) {
            if (theme.title === "Midjorney") {
                await this.getKeys(0);
            }
            await this.$store.dispatch("set", {
                name: "activeThemeName",
                value: theme.title,
            });
            await this.$router.push(
                `/messages/${this.merlinConversationId}/themes/${theme.id}`,
            );
        },
        goToAssistant(theme) {
            this.$store.dispatch("set", {
                name: "activeThemeName",
                value: theme.receiverName,
            });
            this.$router.push(
                `/messages/${this.merlinConversationId}/themes/assistants/${theme.receiverId}`,
            );
        },
        changeChatHidden() {
            localStorage.setItem(
                "chatAsideHidden",
                JSON.stringify(!this.chatAsideHidden),
            );
            this.$store.dispatch("set", {
                name: "chatAsideHidden",
                value: !this.chatAsideHidden,
            });
        },
        async getFriends() {
            await this.$apollo
                .query({
                    query: gql(friendListQuery),
                    variables: {
                        page: 1,
                        itemsPerPage: 20,
                        filter: {
                            term: this.term,
                        },
                    },
                })
                .then((r) => {
                    if (r.data.friendList.length) {
                        this.friends = r.data.friendList;
                    }
                });
        },
        async getKeys() {
            this.keys = [];
            await this.$apollo
                .query({
                    query: gql(getMJKeysQuery),
                    fetchPolicy: "no-cache",
                    client: "chatClient",
                })
                .then((r) => {
                    this.$emit("enableMidjourney", !r.data.getMJKeys.length);
                })
                .catch(() => {
                    this.error = true;
                });
        },
        async getConversations() {
            this.loading = true;
            await this.$apollo
                .query({
                    query: gql`
                        ${getConversationsQuery}
                    `,
                    client: "chatClient",
                    variables: {
                        page: this.page,
                        limit: this.limit,
                        term: this.term,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    const result = r.data.getConversations;
                    this.loading = false;
                    if (result.conversations.length) {
                        result.conversations.forEach((item) => {
                            this.conversations.push({
                                ...item,
                                isTyping: false,
                            });
                        });
                    }
                })
                .catch(() => {
                    this.$emit("chat-server-error");
                });
        },
        async getThemes() {
            await this.$apollo
                .query({
                    query: gql`
                        ${getThemesQuery}
                    `,
                    client: "chatClient",
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.$store.dispatch("set", {
                        name: "chatThemes",
                        value: r.data.getThemes,
                    });
                    this.themes = r.data.getThemes.map((item) => {
                        if (item.title === "Pika") {
                            this.$store.dispatch("set", {
                                name: "chatPikaThemeId",
                                value: item.id,
                            });
                        }
                        if (item.title === "Midjorney") {
                            this.$store.dispatch("set", {
                                name: "chatMidjourneyThemeId",
                                value: item.id,
                            });
                        }
                        if (item.title === "Face Swap") {
                            this.$store.dispatch("set", {
                                name: "chatFaceSwapThemeId",
                                value: item.id,
                            });
                        }
                        if (item.title === "Stable Diffusion Video") {
                            this.$store.dispatch("set", {
                                name: "chatStableDiffusionVideoThemeId",
                                value: item.id,
                            });
                        }
                        if (item.title === "Runway") {
                            this.$store.dispatch("set", {
                                name: "chatRunwayThemeId",
                                value: item.id,
                            });
                        }
                        this.$set(item, "newTitle", item.title);
                        this.$set(item, "renameActive", false);
                        return item;
                    });
                });
        },
        smartLocalizedDate(timestamp) {
            let today = new Date();
            let yesterday = new Date();
            yesterday.setDate(today.getDate() - 1);

            const date = new Date(parseInt(timestamp));
            const minutes =
                (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();

            if (today.toDateString() === date.toDateString())
                return date.getHours() + ":" + minutes;
            if (yesterday.toDateString() === date.toDateString())
                return "вчера"; // + date.getHours() + ":" + minutes;

            return this.$d(date, "dialog", "ru-RU");
        },
        formatLastMessage(conversation) {
            let text = conversation.message;
            if (!text) return " ";

            // Переделать на personId
            /*   if (conversation.senderName !== this.myUser.name) {
                       text = `<b>Вы:</b> ${text}`;
                   }*/

            if (conversation.isTyping) {
                return "печатает...";
            }

            if (text.length >= 31) {
                return text.substr(0, 30) + "...";
            }

            return text;
        },
        openFriendChat(friendId) {
            this.term = "";
            this.friends = [];
            this.$router.push(`/messages/${friendId}`);
        },
    },
    apollo: {
        $subscribe: {
            userTyping: {
                query: gql`
                    ${userTypingSubscription}
                `,
                variables() {
                    return {
                        userId: this.myUserUuid,
                    };
                },
                result(response) {
                    const conversationId =
                        response.data.userTyping.conversationId;
                    const chat = this.conversations.find(
                        (item) => Number(item.id) === Number(conversationId),
                    );

                    if (chat) {
                        chat.isTyping = true;

                        if (this.userTypingTimeouts[conversationId]) {
                            clearTimeout(
                                this.userTypingTimeouts[conversationId],
                            );
                        }

                        this.userTypingTimeouts[conversationId] = setTimeout(
                            () => {
                                chat.isTyping = false;
                            },
                            2000,
                        );
                    }
                },
                skip() {
                    return !this.myUserUuid;
                },
                client: "chatClient",
                fetchPolicy: "no-cache",
            },
            messageSent: {
                query: sharedMixins.queries.messageSentSubscription,
                variables() {
                    return {
                        userId: this.myUserUuid,
                    };
                },
                // Result hook
                result(response) {
                    const newMessage = response.data.messageSent;
                    if (!newMessage || !newMessage.conversationId) {
                        return;
                    }

                    const chat = this.conversations.find(
                        (c) => Number(c.id) === newMessage.conversationId,
                    );
                    chat.message = newMessage.message;

                    if (newMessage.senderId !== this.myUserUuid) {
                        chat.unread += 1;
                    }
                },
                // Skip the subscription
                skip() {
                    return !this.myUserUuid;
                },
                client: "chatClient",
                fetchPolicy: "no-cache",
            },
        },
    },
};
</script>

<style src="./dialogs.scss" lang="scss"></style>
